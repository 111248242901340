import { HANDOUT_BASE_FRAGMENT } from '@/graphql/_Fragments/Handout/Base';

export const SPEAKER_HANDOUTS_FRAGMENT = `
  fragment speakerHandoutsFragment on Speaker {
    uid
    handouts(orderBy: creationTime_asc) {
      ...handoutBaseFragment
    }
  }
  ${HANDOUT_BASE_FRAGMENT}
`;
